import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listTasks, createTask, updateTask } from "../../api/server";
import { useQuery } from "react-query";
import { getSettingsData } from "../../api/server";

import {
  notifyError,
  withNotifications,
} from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "tasks/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listTasks(listParams);

    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load tasks", message: error }));
    }
    return data;
  }
);

export const createNewTask = createAsyncThunk(
  "tasks/create",
  withNotifications(
    createTask,
    "create_new_task",
    "create_new_task_success",
    "create_new_task_error"
  )
);

export const updateNewTask = createAsyncThunk(
  "tasks/update",
  withNotifications(
    updateTask,
    "update_new_task",
    "update_new_task_success",
    "update_new_task_error"
  )
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: undefined,
      $and: [{ $or: [{ status: "opened" }, { status: "inprogress" }] }],
    }, 
    order: { field: "created_at", order: "descend" },
    items: [],
  },
  statusFilter: { $or: [{ status: "opened" }, { status: "inprogress" }] },
  createdByFilter: null,
  typeFilter: null,
  assignedFilter: null,
  showNonActive: false,
  blocked: true,
  templates: {
    items: [],
  },
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setStatusFilter(state, action) {
      const { filter } = action.payload;
      state.statusFilter = filter;
    },
    setAssignedFilter(state, action) {
      const { filter } = action.payload;
      state.assignedFilter = filter;
      state.blocked = false;
    },
    setTextFilter(state, action) {
      const { filter } = action.payload;
      state.page = {
        ...state.page,
        filter: { ...state.page.filter, freeText: filter },
      };
    },
    setNonActive(state, action) {
      const { showNonActive } = action.payload;
      state.showNonActive = showNonActive;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.statusFilter = initialState.statusFilter;
      state.assignedFilter = initialState.assignedFilter;
      state.createdByFilter = initialState.createdByFilter;
      state.showNonActive = initialState.showNonActive;
      state.typeFilter = initialState.typeFilter;
      state.blocked = initialState.blocked;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setStatusFilter,
  setAssignedFilter,
  setCreatedByFilter,
  setTypeFilter,
  setTextFilter,
  setNonActive,
  reset,
} = tasksSlice.actions;

export default tasksSlice.reducer;


/**
 * An action creator responsible for dispatching the filters
 * to the tasks.page.filter key in the redux store.
 * 
 * fetchPage ultimately makes network request with filters retrieved
 * from redux. I assume the filters are stored in redux so that when they
 * are changed a new network request is made when the component renders and
 * perhaps for maintaining some kind of filter state.
 */
export const changePage = (params, query, replace) => (dispatch, getState) => {
  const newState = {
    ...getState().tasks.page,
    ...params,
    order: params.order,
  };
  dispatch(setPage(newState));
  let statusesFilters = [];
  const statuses = getState().tasks.showNonActive
    ? [
        { status: "opened" },
        { status: "inprogress" },
        { status: "cancelled" },
        { status: "completed" },
      ]
    : [{ status: "opened" }, { status: "inprogress" }];

  statuses.forEach((item, i) => {
    const found =
      params.finalfilters && params.finalfilters["status"]
        ? params.finalfilters["status"].find((t) => t === item.status)
        : null;
    if (found) {
      statusesFilters.push(item);
    }
  });

  dispatch(
    setStatusFilter({
      filter:
        statusesFilters.length > 0
          ? { $or: statusesFilters }
          : { $or: statuses },
    })
  );
};

export const loadPage = () => (dispatch, getState) => {
  const filters = [];
  if (getState().tasks.assignedFilter) {
    filters.push(getState().tasks.assignedFilter);
  }
  if (getState().tasks.statusFilter["$or"]) {
    filters.push({ ...getState().tasks.statusFilter });
  }

  // Because we have multiple choice filters outside the actions table in the header
  // we need to clean out those from the filters we got.
  const statePageFilterAnd = getState().tasks?.page?.filter["$and"];
  const listCleaned = statePageFilterAnd?.filter((f) => {
    let addToResult = true;
    if(f["$or"]) {
      if (f["$or"].some(item => !!item.status)) {
        addToResult=false;
      }
    }
    if (!!f.assigned_to_id) {
      addToResult=false;
    }
    return addToResult;
  });

  let finalAndFilters = filters;
  if (listCleaned) {
    finalAndFilters = [...filters, ...listCleaned];
  }
  
  const newState = {
    ...getState().tasks.page,
    filter: {
      ...getState().tasks.page.filter,
      $and: finalAndFilters,
    },
  };

  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().tasks.page));
};
