import axios from "axios";
import { Buffer } from "buffer";
import _ from 'underscore';

const INPUT_DEBOUNCE_TIME = 200;

const API_PREFIX = "/api/v1";

const extractResponse = (res) => {
  if (res.data && res.data.error) {
    throw res.data;
  }
  return res.data;
};

export const fetchList = async (
  resoursePath,
  {
    scopePrefix = "",
    current,
    pageSize: limit,
    order = {},
    filter = {},
    query = {},
    fields = "",
  }
) => {
  const orders = order.field
    ? [`${order.order === "ascend" ? "+" : "-"}${order.field}`]
    : [];
  const { freeText, ...rest } = filter;
  const params = {
    free_text: freeText,
    query: {
      offset: current ? (current - 1) * limit : undefined,
      limit,
      sort: orders,
      filter: rest,
    },
    fields: fields !== "" ? fields : null,
    ...query,
  };
  return extractResponse(
    await axios.get(`${API_PREFIX}/${scopePrefix}${resoursePath}/`, {
      params,
    })
  );
};

//reports

export async function executeReport(reportID, params, scopePrefix) {
  return await axios.get(
    `${API_PREFIX}/${scopePrefix}execute_report/${reportID}`,
    {
      ...params,
    }
  );
}

export async function getReport(params) {
  return await axios.get(
    `${API_PREFIX}/${params.scopePrefix}reports/${params.id}`
  );
}

export async function createReport({ report }) {
  return extractResponse(await axios.post(`${API_PREFIX}/reports/`, report));
}

export async function updateReport({ report }) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/reports/${report.id}`, report)
  );
}

export async function deleteReport(id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/reports/${id}`));
}

//assets

export async function addEntityAsset(entityName, branchId, id, url, params) {
  const uploaded = await axios.post(
    `${API_PREFIX}/branches/${branchId}/${entityName}/${id}/assets/`,
    {
      url: url,
      ...params,
    }
  );

  return extractResponse(uploaded);
}

export async function deleteAsset(params) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}${params.scope}/assets/${params.assetId}`)
  );
}

export async function restoreAsset(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}${params.scope}/assets/${params.assetId}/restore`
    )
  );
}

export async function sendAsset({ scope, assetId, email }) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/${scope}assets/${assetId}/send_email`, {
      email,
    })
  );
}

export async function updateAsset({ id, scope, values }) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/${scope}assets/${id}`, values)
  );
}

export async function getAssetLogs(params) {
  return await axios.get(
    `${API_PREFIX}/${params.scope}assets/${params.assetId}/actions_log`
  );
}

export async function uploadBTLFile(params) {
  const { branchId, file } = params;
  var formData = new FormData();
  formData.append("file", file);
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${branchId}/accounting/upload_btl_response`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
}

export async function uploadImmigrationFile(params) {
  const { file } = params;
  var formData = new FormData();
  formData.append("file", file);
  return extractResponse(
    await axios.post(`${API_PREFIX}/employees/import_foreign_file?`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  );
}

export async function upload(url, file) {
  const buffer = await readFile(file);
  const uploaded = await axios.put(url, new Uint8Array(buffer), {
    headers: {
      "Content-Type": file.type,
      "Content-Disposition": `attachment; filename=${unescape(
        encodeURIComponent(file.fileName)
      )}`,
    },
  });

  return extractResponse(uploaded);
}

function readFile(file) {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsArrayBuffer(file);

    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function () {
      reject(reader.error);
    };
  });
}

export async function generateUploadURL(
  entityName,
  branchId,
  id,
  assetName,
  file
) {
  return await axios.post(
    `${API_PREFIX}/branches/${branchId}/${entityName}/${id}/assets/upload_url/`,
    {
      name: assetName,
      content_type: file.type,
    }
  );
}

export async function generateDownloadURL(entityName, branchId, id, assetID) {
  return await axios.get(
    `${API_PREFIX}/branches/${branchId}/${entityName}/${id}/assets/download_url/${assetID}`
  );
}

export async function generateAssetDownloadURL(scope, assetID) {
  return await axios.get(
    `${API_PREFIX}${scope}/assets/download_url/${assetID}`
  );
}

export async function getAssetFile(url) {
  return await axios
    .get(url, {
      responseType: "arraybuffer",
    })
    .then((response) =>
      Buffer.from(response.data, "binary").toString("base64")
    );
}

export async function getRecord(category, id, prefix = "") {
  return extractResponse(
    await axios.get(`${API_PREFIX}/${prefix}${category}/${id}`)
  );
}

export async function createRecord(category, rec) {
  return extractResponse(await axios.post(`${API_PREFIX}/${category}/`, rec));
}

export async function updateRecord(category, rec) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/${category}/${rec.id}`, rec)
  );
}

export async function deleteRecord(category, id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/${category}/${id}`));
}

// user profile
export async function getCurrentUser() {
  return extractResponse(await axios.get(`${API_PREFIX}/profile/current`));
}

export async function updateCurrentUser(user) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/profile/current`, user)
  );
}

// users
export async function getUser(id) {
  return extractResponse(await axios.get(`${API_PREFIX}/users/id`));
}

export async function listUsers(params) {
  return fetchList("users", params);
}

export async function createInvitation(invitation) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/invitations/`, invitation)
  );
}

export async function fetchMyInvitation() {
  return extractResponse(await axios.get(`${API_PREFIX}/register/invite`));
}

export async function acceptMyInvitation(password) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/register/invite/accept`, { password })
  );
}

export async function listInvitations(params) {
  return fetchList("invitations", params);
}

export async function updateUser(user) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/users/${user.id}`, user)
  );
}

export async function activateUser(user) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/users/${user.id}/activate`)
  );
}

export async function deactivateUser(user) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/users/${user.id}/deactivate`)
  );
}

export async function deleteUser(id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/users/${id}`));
}

//roles

export async function getRoles() {
  return extractResponse(await axios.get(`${API_PREFIX}/roles/`));
}

export async function createRole(role) {
  return extractResponse(await axios.post(`${API_PREFIX}/roles/`, role));
}

export async function updateRole(role) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/roles/${role.id}`, role)
  );
}

export async function deleteRole(id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/roles/${id}`));
}

// clients
export async function getClient(id) {
  return extractResponse(await axios.get(`${API_PREFIX}/clients/${id}`));
}

export async function listClients(params) {
  return fetchList("clients", params);
}

export async function listClientToCollect(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${params.branch}/accounting/clients_to_collect`,
      {
        params,
      }
    )
  );
}

export async function createClient(client) {
  return extractResponse(await axios.post(`${API_PREFIX}/clients/`, client));
}

export async function createPrivateClient(params) {
  const { client, branchId } = params;
  return extractResponse(
    await axios.post(`${API_PREFIX}/branches/${branchId}/clients/`, client)
  );
}

export async function updateClient(client) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/clients/${client.id}`, client)
  );
}

export async function activateClient(params) {
  const prefix = params.branchId ? `/branches/${params.branchId}` : "";
  return extractResponse(
    await axios.put(`${API_PREFIX}${prefix}/clients/${params.id}/activate`)
  );
}

export async function deactivateClient(params) {
  const prefix = params.branchId ? `/branches/${params.branchId}` : "";
  return extractResponse(
    await axios.put(`${API_PREFIX}${prefix}/clients/${params.id}/deactivate`)
  );
}

export async function deleteClient(id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/clients/${id}`));
}

export async function getClientTemplates(id) {
  return extractResponse(await axios.get(`${API_PREFIX}/client_templates/`));
}

export async function addClientsToBranch(params) {
  const clients = params.clients;
  return extractResponse(
    await axios.patch(
      `${API_PREFIX}/branches/${params.branchId}/clients/associate`,
      {
        clients,
      }
    )
  );
}

//orders

export async function getOrder({ scopePrefix, patientId, orderId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/orders/${orderId}`
    )
  );
}

export async function getOrderChangelog({ scopePrefix, patientId, orderId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/orders/${orderId}/changelog`
    )
  );
}

export async function createOrder(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/orders/`,
      params.order
    )
  );
}

export async function updateOrder({ scopePrefix, patientId, id, ...order }) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/orders/${id}`,
      order
    )
  );
}

export async function deleteOrder({ scopePrefix, patientId, id }) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/orders/${id}`
    )
  );
}

export async function listPatientOrders(params) {
  return fetchList(`patients/${params.patientId}/orders`, params);
}

export async function startTrom(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/orders/${params.orderId}/start_trom`,
      { ...params.attachment, destination: params.destination }
    )
  );
}

//scheduling

export async function listScheduling(params) {
  return fetchList(`patients/${params.patientId}/scheduling`, params);
}

export async function listEmployeeSchedulingHistory(params) {
  return fetchList(
    `employees/${params.employeeId}/historical_scheduling`,
    params
  );
}

export async function listPatientSchedulingHistory(params) {
  return fetchList(
    `patients/${params.patientId}/historical_scheduling`,
    params
  );
}

export async function createOrChangeSchedulingEvent(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/scheduling/`,
      params.schedulingEvent
    )
  );
}

export async function changeEventTarrif(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/scheduling/`,
      params.schedulingEvent
    )
  );
}

export async function approveSchedulingEvent(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/scheduling/approval`,
      params.events
    )
  );
}

export async function deleteSchedulingEvents(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/scheduling/delete_events`,
      params.events
    )
  );
}
export async function unlockSchedulingEvents(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/scheduling/unlock`,
      params.events
    )
  );
}

export async function transferScheduling(params) {
  const { from_employee_id, branch_id, ...rest } = params;
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${branch_id}/employees/${from_employee_id}/scheduling/transfer`,
      rest
    )
  );
}

//employees

export async function getEmployee(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  return extractResponse(
    await axios.get(`${API_PREFIX}/${prefix}employees/${params.id}`)
  );
}

export async function getGlobalEmployee(scopePrefix, params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/${scopePrefix}global_employees/search/`, {
      params,
    })
  );
}

export const listEmployees = _.debounce(async function(params) {
  return fetchList("employees", params);
}, INPUT_DEBOUNCE_TIME, true);

export async function getEmployees({ scopePrefix }) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/${scopePrefix}employees`)
  );
}

export async function createEmployee(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/employees/`,
      params.employee
    )
  );
}

export async function updateEmployee({ scopePrefix, employee }) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/${scopePrefix}employees/${employee.id}`,
      employee
    )
  );
}

/**
 * Schedules a log for backend to write to db by writing to local storage.
 * @param {string} label - Category of log message 
 * @param {Object} data - Arbitrary data to log. 
 * @returns {[]Object} - An array of log lines that will be written.
 */
export function scheduleLog(label, data) {
  let logItems = localStorage.getItem("logItems");
  if (logItems == null) {
    logItems = [];
  } else {
    try {
      logItems = JSON.parse(logItems);
    } catch(e) {
      console.warn("error parsing local storage attempting to log data");
      return [];
    }
  }
  const itemToLog = {};
  itemToLog[label] = data;
  logItems.push(itemToLog);
  localStorage.setItem("logItems", JSON.stringify(logItems));
  return logItems;
}

export async function activateEmployee(params) {
  const prefix = params.branchId ? `/branches/${params.branchId}` : "";
  return extractResponse(
    await axios.put(
      `${API_PREFIX}${prefix}/employees/${params.employeeId}/activate`
    )
  );
}

export async function deactivateEmployee(params) {
  const prefix = params.branchId ? `/branches/${params.branchId}` : "";
  return extractResponse(
    await axios.put(
      `${API_PREFIX}${prefix}/employees/${params.employeeId}/deactivate`
    )
  );
}

export async function deleteEmployee(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/employees/${params.id}`
    )
  );
}

export async function createEmployeeWorkPeriods(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/employees/${params.employeeId}/work_periods/current`,
      params.workPeriod
    )
  );
}

export async function updateEmployeeWorkPeriods({
  workPeriod,
  scopePrefix,
  employeeId,
}) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/${scopePrefix}employees/${employeeId}/work_periods/current`,
      workPeriod
    )
  );
}

export async function assignEmployeeToBranch(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/global_employees/${params.employeeId}/assign_branch`
    )
  );
}

export async function generateImmigrationFile() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/employees/export_foreign_file`)
  );
}

//patients absences

export async function getEmployeeAbsences({ scopePrefix, employeeId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}employees/${employeeId}/absences`
    )
  );
}

export async function createEmployeeAbsence(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/employees/${params.employeeId}/absences/`,
      params.absence
    )
  );
}

export async function updateEmployeeAbsence(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/employees/${params.employeeId}/absences/${params.absenceId}`,
      params.absence
    )
  );
}

export async function deleteEmployeeAbsence(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/employees/${params.employeeId}/absences/${params.absenceId}`
    )
  );
}

//employees diaries

export async function sendEmployeesDiaries(data) {
  const { params, branchId } = data;
  const url = `${API_PREFIX}/branches/${branchId}/employees/work_diaries/send_all_mails/`;
  return await axios.post(`${url}`, params);
}

//employees sync

export async function exportEmployees(data) {
  const { params, branchId } = data;
  const url = branchId
    ? `${API_PREFIX}/branches/${branchId}/employees/export_employees/`
    : `${API_PREFIX}/employees/export_employees/`;

  return await axios.post(`${url}`, params);
}

//employees personal_file

export async function getEmployeePersonalFile({ scopePrefix, employeeId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}employees/${employeeId}/personal_file`
    )
  );
}
export async function createEmployeePersonalFile(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/employees/${params.employeeId}/personal_file/`,
      params.personalFileComment
    )
  );
}

// employees scheduling

export async function listEmployeeScheduling(params) {
  return fetchList(`employees/${params.employeeId}/scheduling`, params);
}

export async function transferSchedulingForPeriod(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/employees/${params.employeeId}/scheduling/transfer_period`,
      params
    )
  );
}

export async function getEmployeeOncallUsage(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/employees/${params.employeeId}/scheduling/oncall`,
      { params }
    )
  );
}

// employees effective orders

export async function listEmployeeEffectiveOrders(params) {
  return fetchList(`employees/${params.employeeId}/orders`, params);
}
// employees matching

export async function listEmployeesMatching(params) {
  return fetchList(`employees_matching`, params);
}

// employees effective plan

export async function getEmployeeEffectivePlan(params) {
  const { query } = params;
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/employees/${params.employeeId}/work_plans/for_month`,
      { params: query }
    )
  );
}

// (professional) employee sub employees

export async function updateSubEmployeesStaffWorkerInCharge({ scopePrefix, employeeId,params }){
  return extractResponse(
    await axios.post(`${API_PREFIX}/${scopePrefix}employees/${employeeId}/staff_worker`,{...params})
  );
}


// (professional) employee sub patients

export async function updateSubPatientsStaffWorkerInCharge({ scopePrefix, employeeId,params }){
  return extractResponse(
    await axios.post(`${API_PREFIX}/${scopePrefix}patients/staff_worker`,{...params})
  );
}

//patients

export async function getPatient(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  const route = params.fromCrm ? "crm/patients" : "patients";
  return extractResponse(
    await axios.get(`${API_PREFIX}/${prefix}${route}/${params.id}`)
  );
}

export async function getGlobalPatient(scopePrefix, params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/${scopePrefix}global_patients/search/`, {
      params,
    })
  );
}

export const listPatients = _.debounce(async function(params) {
  return fetchList("patients", params);
}, INPUT_DEBOUNCE_TIME, true);

export async function getPatients({ scopePrefix }) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/${scopePrefix}patients`)
  );
}

export async function createPatient(params) {
  const route = params && params.fromCrm ? "crm/patients" : "patients";
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/${route}/`,
      params.patient
    )
  );
}

export async function updatePatient(params) {
  const route = params && params.fromCrm ? "crm/patients" : "patients";
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/${route}/${params.patient.id}`,
      params.patient
    )
  );
}

export async function activatePatient(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patient.id}/activate`
    )
  );
}

export async function deactivatePatient(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patient.id}/deactivate`
    )
  );
}

export async function deletePatient(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.id}`
    )
  );
}

export async function transferPatientToBranch(params) {
  const { branchId, patientId, currentBranchId } = params;
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${currentBranchId}/patients/${patientId}/transfer_branch/${branchId}`
    )
  );
}

export async function uploadBtlEmergencyFile(params) {
  const { branchId, ...rest } = params;
  let formData = new FormData();
  Object.keys(rest).forEach((key) => {
    formData.append(key, rest[key]);
  });
  const branchRoute = branchId ? `/branches/${branchId}` : ``;
  return extractResponse(
    await axios.post(
      `${API_PREFIX}${branchRoute}/patients/import_emergency_file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
}

//patients sync

export async function exportPatients(data) {
  const { params, branchId } = data;
  const url = branchId
    ? `${API_PREFIX}/branches/${branchId}/patients/export_patients/`
    : `${API_PREFIX}/patients/export_patients/`;

  return await axios.post(`${url}`, params);
}

//patients emergency export

export async function exportEmergency(data) {
  const { params, branchId } = data;
  const url = `${API_PREFIX}/branches/${branchId}/patients/export_emergency_patients/`;
  return await axios.post(`${url}`, params);
}

//patients absences

export async function getPatientAbsences({ scopePrefix, patientId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/absences`
    )
  );
}

export async function createPatientAbsence(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/absences/`,
      params.absence
    )
  );
}

export async function updatePatientAbsence(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/absences/${params.absenceId}`,
      params.absence
    )
  );
}

export async function deletePatientAbsence(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/absences/${params.absenceId}`
    )
  );
}

//patients references

export async function getPatientReferences({ scopePrefix, patientId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/references`
    )
  );
}

export async function createPatientReference(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/references/`,
      params.reference
    )
  );
}

export async function updatePatientReference(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/references/${params.referenceId}`,
      params.reference
    )
  );
}

export async function deletePatientReference(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/references/${params.referenceId}`
    )
  );
}

//patients hospitalizations

export async function getPatientHospitalizations({ scopePrefix, patientId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/hospitalizations`
    )
  );
}

export async function createPatientHospitalization(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/hospitalizations/`,
      params.hospitalization
    )
  );
}

export async function updatePatientHospitalization(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/hospitalizations/${params.hospitalizationId}`,
      params.hospitalization
    )
  );
}

export async function deletePatientHospitalization(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/hospitalizations/${params.hospitalizationId}`
    )
  );
}

//patients personal_file

export async function getPatientPersonalFile({ scopePrefix, patientId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/personal_file`
    )
  );
}
export async function createPatientPersonalFile(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/personal_file/`,
      params.personalFileComment
    )
  );
}

//work planSlice

export async function getWorkPlan(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${prefix}patients/${params.patientId}/work_plans/current`
    )
  );
}

export async function simulateWorkPlan(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.id}/work_plans/simulate_workplan`,
      params.work_plan
    )
  );
}

export async function updateWorkPlan(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.id}/work_plans/current`,
      params.work_plan
    )
  );
}

//work diaries

// export async function getWorkDiary(params) {
//   const prefix = params.admin ? `` : `branches/${params.branchId}/`;
//   return extractResponse(
//     await axios.get(
//       `${API_PREFIX}/${prefix}patients/${params.patientId}/work_diaries/${params.workDiaryId}`
//     )
//   );
// }

export async function createWorkDiary(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/work_diaries`,
      params.workDiary
    )
  );
}

// export async function updateWorkDiary(params) {
//   return extractResponse(
//     await axios.put(
//       `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/work_diaries/${params.workDiary.id}`,
//       params.workDiary
//     )
//   );
// }

export async function deleteWorkDiary(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/work_diaries/${params.workDiaryId}`
    )
  );
}

export async function listWorkDiaries(params) {
  return fetchList(`patients/${params.patientId}/work_diaries`, params);
}

export async function listScheduledWorkDiaries(params) {
  return fetchList(
    `patients/${params.patientId}/scheduled_work_diaries`,
    params
  );
}

export async function listEmployeeWorkDiaries(params) {
  return fetchList(
    `employees/${params.employeeId}/scheduled_work_diaries`,
    params
  );
}

export async function listBranchWorkDiaries(params) {
  return fetchList(
    `branches/${params.branchId}/employees/work_diaries`,
    params
  );
}

export async function sendWorkDiaries(params) {
  return await axios.post(
    `${API_PREFIX}/employees/${params.employeeId}/scheduled_work_diaries/send_mail/`,
    {
      ...params,
    }
  );
}

// branch assets

export async function generateBranchUploadURL(branchId, assetName, file) {
  return await axios.post(
    `${API_PREFIX}/branches/${branchId}/assets/upload_url/`,
    {
      name: assetName,
      content_type: file.type,
    }
  );
}

export async function generateBranchDownloadURL(branchId, assetID) {
  return await axios.post(
    `${API_PREFIX}/branches/${branchId}/assets/download_url/${assetID}`
  );
}

export async function addBranchAsset(branchId, assetName, url) {
  const uploaded = await axios.post(
    `${API_PREFIX}/branches/${branchId}/assets/`,
    {
      url: url,
      name: assetName,
    }
  );

  return extractResponse(uploaded);
}

export async function deleteBranchAsset(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/assets/${params.assetId}`
    )
  );
}

//house Visits
export async function getHouseVisitSettings({ scopePrefix, patientId }) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/house_visits/settings`
    )
  );
}

export async function createHouseVisitSettings({
  scopePrefix,
  patientId,
  settings,
}) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/house_visits/settings`,
      settings
    )
  );
}

export async function updateHouseVisitSettings({
  scopePrefix,
  patientId,
  settings,
}) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/${scopePrefix}patients/${patientId}/house_visits/settings/${settings.id}`,
      settings
    )
  );
}

export async function listHouseVisits(params) {
  return fetchList(`patients/${params.patientId}/house_visits/events`, params);
}

export async function listEmployeeHouseVisits(params) {
  return fetchList(
    `employees/${params.employeeId}/house_visits/events`,
    params
  );
}

export async function listControllersHouseVisits(params) {
  return fetchList(
    `employees/${params.employeeId}/house_visits/needs_approval`,
    params
  );
}

export async function employeeHouseVisitsUpdate(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/${prefix}employees/${params.employeeId}/house_visits/events`,
      params.events
    )
  );
}

export async function employeeHouseVisitsTransfer(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${prefix}employees/${params.employeeId}/house_visits/events/assign-employee`,
      params
    )
  );
}

export async function verifyControllersHouseVisits(params) {
  const { ids, scopePrefix, employeeId } = params;
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${scopePrefix}employees/${employeeId}/house_visits/verify`,
      { house_visit_ids: ids }
    )
  );
}

export async function getHouseVisit(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${prefix}patients/${params.patientId}/house_visits/events/${params.houseVisitsId}`
    )
  );
}

export async function createHouseVisit(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/house_visits/events`,
      params.house_visit
    )
  );
}

export async function updateHouseVisit(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/house_visits/events/${params.house_visit.id}`,
      params.house_visit
    )
  );
}

export async function deleteHouseVisit(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/patients/${params.patientId}/house_visits/events/${params.houseVisitId}`
    )
  );
}

//branches
export async function listBranches(params) {
  return fetchList("branches", params);
}

export async function createBranch(branch) {
  return extractResponse(await axios.post(`${API_PREFIX}/branches/`, branch));
}

export async function updateBranch(branch) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/branches/${branch.id}`, branch)
  );
}

export async function deleteBranch(id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/branches/${id}`));
}

//tariffsSlice

export async function getTariffs() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/settings/salary/tariffs`)
  );
}

export async function createTariff(tariff) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/settings/salary/tariffs/`, tariff)
  );
}

export async function updateTariff(tariff) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/salary/tariffs/${tariff.id}`,
      tariff
    )
  );
}

export async function createTariffVersion(version) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/settings/salary/tariffs/${version.tariff_id}/version`,
      version
    )
  );
}

export async function updateTariffVersion(version) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/salary/tariffs/${version.tariff_id}/version/${version.id}`,
      version
    )
  );
}

export async function deleteTariffVersion(version) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/settings/salary/tariffs/${version.tariff_id}/version/${version.id}`
    )
  );
}

export async function deleteTariff(id) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/settings/salary/tariffs/${id}`)
  );
}

//clientsTariffsSlice

export async function getRestrictedclientsTariffs() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/settings/accounting/client_tariffs`)
  );
}

export async function getclientsTariffs() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/client_tariffs`));
}

export async function createClientsTariff(tariff) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/settings/accounting/client_tariffs/`,
      tariff
    )
  );
}

export async function updateClientsTariff(tariff) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/accounting/client_tariffs/${tariff.id}`,
      tariff
    )
  );
}

export async function deleteClientsTariff(id) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/settings/accounting/client_tariffs/${id}`)
  );
}

export async function createClientsTariffVersion(version) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/settings/accounting/client_tariffs/${version.tariff_id}/version`,
      version
    )
  );
}

export async function updateClientsTariffVersion(version) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/accounting/client_tariffs/${version.tariff_id}/version/${version.id}`,
      version
    )
  );
}

export async function deleteClientsTariffVersion(version) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/settings/accounting/client_tariffs/${version.tariff_id}/version/${version.id}`
    )
  );
}

//contractSlice

export async function getContracts() {
  return extractResponse(await axios.get(`${API_PREFIX}/settings/contracts`));
}

export async function createContract(contract) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/settings/contracts/`, contract)
  );
}

export async function updateContract(contract) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/settings/contracts/${contract.id}`, contract)
  );
}

export async function deleteContract(id) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/settings/contracts/${id}`)
  );
}

export async function createContractVersion(version) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/settings/contracts/${version.contract_id}/version`,
      version
    )
  );
}

export async function updateContractVersion(version) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/contracts/${version.contract_id}/version/${version.id}`,
      version
    )
  );
}

export async function deleteContractVersion(version) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/settings/contracts/${version.contract_id}/version/${version.id}`
    )
  );
}

//Holidays
export async function listHolidays(params) {
  return fetchList("meta/system_tables/salary/holidays", params);
}

// gaps

export async function listGaps(params) {
  return fetchList(`gaps/${params.resource}`, params);
}

export async function sendDiariesReminders(params) {
  const { scopePrefix } = params;
  const { freeText, ...filter } = params.page.filter;
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${scopePrefix}gaps/order_summaries/send_sms_reminders`,
      null,
      {
        params: {
          free_text: freeText,
          query: { filter },
          ...params.query,
        },
      }
    )
  );
}

// tasks
export async function listTasks(params) {
  return fetchList("tasks", params);
}

export async function listTaskCreators(branchID) {
  return await axios.get(`${API_PREFIX}/branches/${branchID}/tasks/creators`);
}

export async function taskListLastUpdaters(branchID) {
  return await axios.get(`${API_PREFIX}/branches/${branchID}/tasks/last_updaters`);
}

export async function createTask(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/tasks`,
      params.task
    )
  );
}

export async function updateTask(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/tasks/${params.task.id}`,
      params.task
    )
  );
}

//salary
export const listEmployeesSalary = _.debounce(async function(params) {
  return fetchList(params.path, params);
}, INPUT_DEBOUNCE_TIME, true);

export async function listEmployeesSalaryLines(params) {
  return fetchList(params.path, params);
}

export async function createSalaryLine(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/salary/${params.employeeId}/salary_lines`,
      params.salaryLine
    )
  );
}

export async function updateSalaryLine(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/salary/${params.employeeId}/salary_lines/${params.id}`,
      params.salaryLine
    )
  );
}

export async function deleteSalaryLine(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/salary/${params.employeeId}/salary_lines/${params.id}`
    )
  );
}

export async function approveEmployeeSalary(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/salary/${params.employeeId}/approve`,
      params.period
    )
  );
}

export async function recalculateEmployeeSalary(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/salary/${params.employeeId}/recalculate`,
      params.period
    )
  );
}

export async function runMonthSalary(params) {
  return extractResponse(
    await axios.patch(
      `${API_PREFIX}/branches/${params.branchId}/salary/global/run_salary`,
      params.period
    )
  );
}

export async function approveAllMonthSalary(params) {
  return extractResponse(
    await axios.patch(
      `${API_PREFIX}/branches/${params.branchId}/salary/global/approve`,
      params.period
    )
  );
}

export async function lockSalary(params) {
  return extractResponse(
    await axios.patch(
      `${API_PREFIX}/branches/${params.branchId}/salary/global/lock`,
      params.period
    )
  );
}

export async function unlockSalary(params) {
  return extractResponse(
    await axios.patch(
      `${API_PREFIX}/branches/${params.branchId}/salary/global/unlock`,
      params.period
    )
  );
}

export async function generateSalaryFile(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/salaryexport/download`,
      params.period
    )
  );
}

export async function exportSalaryFile(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/salaryexport/export`,
      params.period
    )
  );
}

export async function getEmployeeEffectiveOrders(params) {
  const { query } = params;
  const response = await axios.get(
    `${API_PREFIX}/branches/${params.branchId}/salary/${params.employeeId}/effective_orders`,
    { params: query }
  );
  return response.data;
}

//payment requests

export async function listPaymentRequests(params) {
  return fetchList(params.path, params);
}
export async function updatePaymentRequest(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/accounting/update_payment_request`,
      params
    )
  );
}

//prospectuses

export async function generateProspectuses(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${params.path}accounting/generate_prospectuses`,
      params
    )
  );
}

export async function dryRunGenerateProspectuses(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${params.path}accounting/dry_run_generate_prospectuses`,
      params
    )
  );
}

export async function listProspectuses(params) {
  return fetchList(params.path, params);
}

export async function generateProspectus(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.parentId}/create_child`
    )
  );
}

export async function generateChildProspectus(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.parentId}/create_child`
    )
  );
}

export async function lockProspectus(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}/lock`
    )
  );
}

export async function getProspectus(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}`
    )
  );
}

export async function listProspectusLines(params) {
  return fetchList(params.path, params);
}

export async function getProspectusLine(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}/lines/${params.lineId}`
    )
  );
}

export async function updateProspectusLine(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}/lines/${params.lineId}`,
      params.prospectusLine
    )
  );
}
export async function createProspectusFromLine(values) {
  const { params, branchId, prospectusId, lineId } = values;
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${branchId}/accounting/prospectuses/${prospectusId}/lines/${lineId}/create_new`,
      params
    )
  );
}

export async function deleteProspectusLine(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}/lines/${params.lineId}`
    )
  );
}

export async function approveAllProspectusLines(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}/approve_all`
    )
  );
}

export async function rejectAllProspectusLines(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${params.branchId}/accounting/prospectuses/${params.prospectusId}/reject_all`
    )
  );
}

export async function uploadBtlResponse(branchId, assetName, file) {
  return await axios.post(
    `${API_PREFIX}/branches/${branchId}/accounting/upload_btl_response/`,
    {
      name: assetName,
      content_type: file.type,
    }
  );
}

export async function getAccountingSummary(params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/${params.scope}accounting/summary/`, {
      params,
    })
  );
}

export async function exportProspectuses(data) {
  const { params, branchId } = data;
  const url = branchId
    ? `${API_PREFIX}/branches/${branchId}/accounting/export_prospectuses/`
    : `${API_PREFIX}/accounting/export_prospectuses/`;

  return await axios.post(`${url}`, params);
}

//generate btl files

export async function generateEmployeesFile(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${params.branchId}/accounting/export_employees`,
      { params: params.period }
    )
  );
}

export async function generateProfessionalEmployeesFile(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${params.branchId}/accounting/export_professional_employees`,
      { params: params.period }
    )
  );
}

export async function generateBtlFile(params, branchId) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${branchId}/accounting/export_btl`,
      { params }
    )
  );
}

export async function generateKerenFile(params, branchId) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/branches/${branchId}/accounting/export_holocast_fund`,
      { params }
    )
  );
}

// permissions

export async function getPermissions() {
  return extractResponse(await axios.get(`${API_PREFIX}/permissions/`));
}

// organizationSlice
export async function getOrganization() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/company`));
}

//only allowed by users with settings permissions
export async function getRestrictedSettings() {
  return extractResponse(await axios.get(`${API_PREFIX}/settings/company`));
}

export async function getSettings() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/company`));
}

export async function updateOrganization(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/company/${params.id}`,
      params.organization
    )
  );
}
// get select lists
export async function getClients() {
  return extractResponse(await axios.get(`${API_PREFIX}/clients/`));
}

export async function getOrganizations() {
  return extractResponse(await axios.get(`${API_PREFIX}/ `));
}

//meta
export async function getBanks() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/banks/`));
}

export async function getBankBranches({ id }) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/banks/${id}/branches/`)
  );
}

export async function getReligions() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/religions/`));
}

export async function getGenders() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/genders/`));
}
export async function listLanguages(params) {
  return fetchList("meta/languages", params);
}

export async function listServiceTypes(params) {
  return fetchList("meta/service_types", params);
}

export async function getMetaData({ name }) {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/${name}/`));
}

//only allowed by users with settings permissions
export async function getRestrictedSettingsData({ name }) {
  return extractResponse(await axios.get(`${API_PREFIX}/settings/${name}/`));
}

export async function listRestrictedSettingsData(params) {
  return fetchList(`settings/${params.name}`, params);
}

export async function getSettingsData({ name }) {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/${name}/`));
}

export async function listSettingsData(params) {
  return fetchList(`meta/${params.name}`, params);
}

export async function getSystemTableData({ name }) {
  return extractResponse(await axios.get(`${API_PREFIX}/${name}/`));
}

export async function getEmployee_types() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/employee_types/`));
}
export async function getFamily_status() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/family_status/`));
}
export async function getPatient_status() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/patient_status/`));
}
export async function getHealth_funds() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/health_funds/`));
}
export async function getClientCategories({ scopePrefix }) {
  if (scopePrefix) {
    return extractResponse(
      await axios.get(`${API_PREFIX}/${scopePrefix}client_categories/`)
    );
  }
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/client_categories/`)
  );
}
export async function getBranchClientCategories(branchID) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/branches/${branchID}/client_categories/`)
  );
}
export async function getServiceTypes() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/service_types/`));
}
export async function getEmployeeCareDemands() {
  return extractResponse(await axios.get(`${API_PREFIX}/care_demands/`));
}
export async function getCountries() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/countries/`));
}
export async function getForeignCorporations() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/foreign_corporations/`)
  );
}

export async function getCertifications() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/certifications/`));
}

export async function getEducation() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/education/`));
}

export async function getPatientCategories() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/patient_categories/`)
  );
}

export async function getDepartments() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/departments/`));
}

export async function getRelativeTypes() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/relative_types/`));
}

export async function getAbsenceReasons() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/absence_reasons/`)
  );
}

export async function getBranchGroups() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/branch_groups/`)
  );
}

export async function getClientTariffs() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/client_tariffs/`));
}

export async function getRefundReasons() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/refund_reasons/`)
  );
}

export async function getAssetCategories() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/asset_categories/`)
  );
}

export async function getAssetSubCategories() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/asset_sub_categories/`)
  );
}
export async function getAssetStatuses() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/asset_statuses/`)
  );
}

export async function getLanguages() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/languages/`)
  );
}

export async function getHospitalizationInstitutions() {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/meta/system_tables/hospitalization_institutions/`
    )
  );
}

export async function getCourses() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/courses/`)
  );
}

export async function getProfessionalRoles() {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/professional_roles/`)
  );
}

export async function getReferrerTypes() {
  return extractResponse(await axios.get(`${API_PREFIX}/meta/referrer_types/`));
}

export async function fetchApiData(path) {
  return extractResponse(await axios.get(`${API_PREFIX}/${path}`));
}

export async function listApiData(params) {
  return fetchList(params.path, params);
}

export async function getSystemTable(source) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/settings/system_tables/${source}/`)
  );
}
export async function getMetaSystemTable(source) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/meta/system_tables/${source}/`)
  );
}

export async function listSystemTable(params) {
  return fetchList(`settings/system_tables/${params.source}`, params);
}

export async function updateSystemTable(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/system_tables/${params.source}/${params.record.id}`,
      params.record
    )
  );
}

export async function createSystemTableItem(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/settings/system_tables/${params.source}/`,
      params.record
    )
  );
}

export async function deleteSystemTableItem(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}/settings/system_tables/${params.source}/${params.record.id}`
    )
  );
}

export async function changeSystemTableOrder(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/settings/system_tables/${params.source}`,
      params.record
    )
  );
}

//tenants

export async function listTenants(params) {
  return fetchList("tenants", params);
}

export async function createTenant(tenant) {
  return extractResponse(await axios.post(`${API_PREFIX}/tenants/`, tenant));
}

export async function updateTenant(tenant) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/tenants/${tenant.id}`, tenant)
  );
}

export async function deleteTenant(id) {
  return extractResponse(await axios.delete(`${API_PREFIX}/tenants/${id}`));
}

export async function activateTenant(id) {
  return extractResponse(
    await axios.patch(`${API_PREFIX}/tenants/${id}/activate`)
  );
}

export async function deactivateTenant(id) {
  return extractResponse(
    await axios.patch(`${API_PREFIX}/tenants/${id}/deactivate`)
  );
}

export async function getTenant(id) {
  return extractResponse(await axios.get(`${API_PREFIX}/tenant/${id}`));
}

export async function getTenantBranches(id) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/tenants/${id}/branches`)
  );
}

export async function getCurrentTenant() {
  return extractResponse(await axios.get(`${API_PREFIX}/current_tenant`));
}

export async function runTenantJob(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/tenants/${params.tenant.id}/jobs/${params.job}/`,
      { branch_id: params.branch }
    )
  );
}

//features

export async function listFeatures(params) {
  return fetchList("features", params);
}

//sub projects

export async function listSubProjects(params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/branches/${params.branchId}/subprojects/`)
  );
}

// sub SubReferrers

export async function listSubReferrers(params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/patients/sub_referrers/`)
  );
}

//CRM

//activities
export async function createActivity(params) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/${params.scope}/${params.entity}/${params.entityId}/${params.activityType}/`,
      params.activity
    )
  );
}

export async function updateActivity(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/${params.scope}/${params.entity}/${params.entityId}/${params.activityType}/${params.activity.id}`,
      params.activity
    )
  );
}

export async function deleteActivity(params) {
  return extractResponse(
    await axios.delete(
      `${API_PREFIX}${params.scope}/${params.entity}/${params.entityId}/${params.activityType}/${params.activityId}`
    )
  );
}

export async function getActivity(params) {
  return extractResponse(
    await axios.get(
      `${API_PREFIX}/${params.entity}/${params.entityId}/${params.activityType}/${params.activity.id}`
    )
  );
}

//leads

export async function getLead(params) {
  return extractResponse(await axios.get(`${API_PREFIX}/leads/${params.id}`));
}

export async function listLeads(params) {
  return fetchList("leads", params);
}

export async function getLeads({ scopePrefix }) {
  return extractResponse(await axios.get(`${API_PREFIX}/leads`));
}

export async function createLead(params) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/leads/`, params.patient)
  );
}

export async function updateLead(params) {
  return extractResponse(
    await axios.put(`${API_PREFIX}/leads/${params.lead.id}`, params.lead)
  );
}

export async function deleteLead(params) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/leads/${params.id}`)
  );
}

//opportunities

export async function getOpportunity(params) {
  const prefix = params.admin ? `` : `branches/${params.branchId}/`;
  return extractResponse(
    await axios.get(`${API_PREFIX}/${prefix}crm/patients/${params.id}`)
  );
}

export async function listOpportunities(params) {
  return fetchList("crm/patients", params);
}

export async function getOpportunities({ scopePrefix }) {
  return extractResponse(await axios.get(`${API_PREFIX}/opportunities`));
}

export async function createOpportunity(params) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/opportunities/`, params.patient)
  );
}

export async function updateOpportunity(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/opportunities/${params.opportunity.id}`,
      params.opportunity
    )
  );
}

export async function deleteOpportunity(params) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/opportunities/${params.id}`)
  );
}

//contacts

export async function getContact(params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/contacts/${params.id}`)
  );
}

export async function listContacts(params) {
  return fetchList("contacts", params);
}

export async function getContacts({ scopePrefix }) {
  return extractResponse(await axios.get(`${API_PREFIX}/contacts`));
}

export async function createContact(params) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/contacts/`, params.patient)
  );
}

export async function updateContact(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/contacts/${params.contact.id}`,
      params.contact
    )
  );
}

export async function deleteContact(params) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/contacts/${params.id}`)
  );
}

//campaigns

export async function getCampaign(params) {
  return extractResponse(
    await axios.get(`${API_PREFIX}/campaigns/${params.id}`)
  );
}

export async function listCampaigns(params) {
  return fetchList("campaigns", params);
}

export async function getCampaigns({ scopePrefix }) {
  return extractResponse(await axios.get(`${API_PREFIX}/campaigns`));
}

export async function createCampaign(params) {
  return extractResponse(
    await axios.post(`${API_PREFIX}/campaigns/`, params.patient)
  );
}

export async function updateCampaign(params) {
  return extractResponse(
    await axios.put(
      `${API_PREFIX}/campaigns/${params.contact.id}`,
      params.contact
    )
  );
}

export async function deleteCampaign(params) {
  return extractResponse(
    await axios.delete(`${API_PREFIX}/campaigns/${params.id}`)
  );
}

//ok2go

export async function listOk2goStatus(params, activeSection) {
  return fetchList(`ok2go/${activeSection}/status/`, params);
}

export async function syncOk2go({ activeBranch, activeSection, payload }) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${activeBranch}/ok2go/${activeSection}/sync/`,
      payload
    )
  );
}

export async function resetOk2go({ activeBranch, activeSection, payload }) {
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/branches/${activeBranch}/ok2go/${activeSection}/clear/`,
      payload
    )
  );
}

export async function listOk2goPatientStatus(params, patient_id) {
  return fetchList(`ok2go/events/status/${patient_id}`, params);
}

//revaha

export async function uploadRevahaSchedules(params) {
  const { file, activeBranch } = params;
  var formData = new FormData();
  formData.append("file", file);
  return extractResponse(
    await axios.post(
      `${API_PREFIX}/ok2go-revaha/import_ok2go_revaha_schedules`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  );
}

//employees sync

export async function exportRevaha(data) {
  const { params, branchId, activeSection, entity } = data;
  const url = branchId
    ? `${API_PREFIX}/branches/${branchId}/ok2go-revaha/export_ok2go_revaha_${activeSection}/`
    : `${API_PREFIX}/ok2go-revaha/export_ok2go_revaha_${activeSection}/`;

  return await axios.post(`${url}`, params);
}
